import styled from 'styled-components'
import theme from '../../styles/theme'
import {motion} from 'framer-motion'

export const ProductPage = styled(motion.section)`
  position: relative;
  .firstSection {
    position: relative;
    z-index: 20;
  }
  .ProductContainer {
    display: flex;
    width: 100vw;
    background-color: #e6e6e6;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      flex-direction: column;
    }
    > div {
      flex: 1;
      .gatsby-image-wrapper {
        width: 100%;
      }
    }
    .left {
      display: flex;
      align-items: center;
      &.buySection {
        align-items: flex-start;
      }
    }
    .right {
      &.buySection {
        padding: 0 ${theme.paddingAround};
        background-color: ${theme.colors.dark};
      }
    }
  }
  .div100vh {
    height: 100vh;
    width: 100vw;
    pointer-events: none;
  }
  .okBack {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow: scroll;
    width: 100%;
    height: 100vh;
    background-color: ${theme.colors.dark};
    margin-top: 50px;
  }
`

export const ProductComponent = styled.section`
  #product-component {
    display: flex;
    justify-content: center;
    @media screen and (max-width: ${theme.breakpoints.md}px) {
      margin-top: 120px;
    }
    .has-image {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      margin: 0;
      flex-wrap: wrap;
      max-width: 1000px;
      @media screen and (max-width: ${theme.breakpoints.md}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
      }
      .shopify-buy__select-icon {
        display: none;
      }
      > * {
        display: flex;
        overflow: auto;
        text-align: left;
        @media screen and (max-width: 700px) {
          display: block;
          padding-left: 0;
          width: 80vw;
        }
        span {
          font-size: 16px;
        }
        .shopify-buy__product__actual-price {
          font-size: 18px;
        }
        .visuallyhidden {
          display: none;
        }
        .shopify-buy__option-select {
          margin-top: 30px;
          min-width: 170px;
        }
        .shopify-buy__option-select__select {
          width: 100%;
          font-size: 18px;
          padding: 5px 0 5px 0;
          margin: 0 0 0 0;
          color: black;
        }
        .shopify-buy__option-select-wrapper select {
          position: relative;
          font-family: 'Arial';
          display: inline-block;
          width: 100%;
          cursor: pointer;
          padding: 1rem 2rem;
          outline: 0;
          border: 1px solid ${theme.colors.light};
          border-radius: 0px;
          background: transparent;
          color: ${theme.colors.light};
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          transition: background-color 0.3s;
        }
        .shopify-buy__option-select-wrapper select::-ms-expand {
          display: none;
        }
        .shopify-buy__option-select-wrapper select:hover {
          color: #000000;
          background: #dfdfdf;
        }
        .shopify-buy__option-select-wrapper select:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        .shopify-buy__option-select-wrapper {
          position: relative;
        }
        .shopify-buy__select-icon {
          display: flex;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate(0, -50%);
          width: 20px;
          height: 20px;
          filter: invert(100%);
        }
        .select_arrow {
          position: absolute;
          top: 16px;
          right: 15px;
          pointer-events: none;
          border-style: solid;
          border-width: 8px 5px 0px 5px;
          border-color: #7b7b7b transparent transparent transparent;
        }
        .shopify-buy__option-select-wrapper select:hover ~ .select_arrow,
        .shopify-buy__option-select-wrapper select:focus ~ .select_arrow {
          border-top-color: #000000;
        }
        .seleshopify-buy__option-select-wrapperct
          select:disabled
          ~ .select_arrow {
          border-top-color: #cccccc;
        }
      }
      .shopify-buy__btn-wrapper {
        text-align: right;
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          text-align: center;
        }
        .shopify-buy__btn {
          background-color: transparent;
          color: ${theme.colors.light};
          border: solid 1px black;
          box-shadow: 5px 5px 0px 1px #000000;
          text-transform: uppercase;
          ${
            '' /* height: 40px;
          width: 170px; */
          }
          cursor: pointer;
          margin: 30px 0 30px 0;
          border: 1px solid #fff;
          border-radius: 0px;
          font-size: 1rem;
          transition: box-shadow 0.15s ease-in-out,
            background-color 0.15s ease-in-out, color 0.15s ease-in-out;
          &:hover {
            box-shadow: 0px 0px 0px 1px #000000;
            background-color: ${theme.colors.light};
            color: ${theme.colors.dark};
          }
        }
      }
      > :first-child {
        .main-image-wrapper {
          display: flex;
          justify-content: center;
          width: 80%;
          margin-left: 10%;
          margin-top: 5%;
          img {
            object-fit: cover;
            width: 90%;
            max-width: 500px;
            max-height: 500px;
          }
          .carousel-button {
            width: 5%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            color: transparent;
            background-color: transparent;
            border: transparent;
            cursor: pointer;
            transition: transform 0.1s;
            border-radius: 20%;
            &:focus {
              outline: none;
            }
            &:active {
              transform: scale(0.9);
            }
            &:active + .carousel-button-arrow {
              color: white;
            }
            .carousel-button-arrow {
              position: absolute;
              // transform: translateX(-13px) translateY(1px)
              height: 20px;
              -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
              filter: invert(100%);
            }
            &.carousel-button--previous {
              .carousel-button-arrow {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      &:not(:first-child) {
        display: none !important;
      }
    }
  }
`
