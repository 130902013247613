import React, {useContext, useEffect, useState} from 'react'
import * as Styled from './ProductPage.style'
import Context from '../../utils/context/Context'

import {GatsbyImage} from 'gatsby-plugin-image'
import {navigate} from 'gatsby'
import {document, window} from 'browser-monads'
import Helmet from 'react-helmet'

import Shop from '../../components/Shop'

function ProductPage({pageContext}) {
  const [isScrollShop, setIsScrollShop] = useState(false)
  const id = pageContext.bigwaxId
  const titre = pageContext.titre
  const {
    products,
    buyButtonOptions
    // shopClickedFromProduct,
    // setShopClickedFromProduct
  } = useContext(Context)

  const currentProduct = products?.filter((product) => {
    return product.id_bigwax === id
  })[0]

  // go back to previous page when scrolled to the bottom
  // Activate scroll shop when product fully scrolled
  useEffect(() => {
    // window.scrollTo(0, 0)
    const handleScroll = (e) => {
      // console.log(window.innerHeight + document.documentElement.scrollTop)
      // console.log(document.documentElement.offsetHeight)
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 20
      ) {
        //   console.log('NAVIGATE')
        //   navigate('/?shop=fromproduct')
        //   // navigate('/musique')
        setIsScrollShop(true)
      } else {
        setIsScrollShop(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  // INIT SHOPIFY
  useEffect(() => {
    let scriptURL =
      'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit()
      } else {
        loadScript()
      }
    } else {
      loadScript()
    }
    function loadScript() {
      var script = document.createElement('script')
      script.async = true
      script.src = scriptURL
      ;(
        document.getElementsByTagName('head')[0] ||
        document.getElementsByTagName('body')[0]
      ).appendChild(script)

      script.onload = ShopifyBuyInit
    }
    function ShopifyBuyInit() {
      var client = window.ShopifyBuy.buildClient({
        domain: process.env.GATSBY_SHOPIFY_STORE_URL,
        storefrontAccessToken: '31682f9bb9f4efdfdcfd96fb08af4c27'
      })
      window.ShopifyBuy.UI.onReady(client).then(function (ui) {
        ui.createComponent('product', {
          id: id,
          node: document.getElementById('product-component'),
          moneyFormat: '%E2%82%AC%7B%7Bamount_with_comma_separator%7D%7D',
          options: buyButtonOptions
        })
      })
    }
  }, [currentProduct])

  const renderImages = (images) => {
    let returnImages = []
    for (let i = 1; i < images.length; i = i + 2) {
      returnImages.push(
        <div className="ProductContainer" key={i}>
          <div className="left">
            <GatsbyImage image={currentProduct.images[i]} alt="produit" />
          </div>
          {currentProduct.images[i + 1] && (
            <div className="right">
              <GatsbyImage image={currentProduct.images[i + 1]} alt="produit" />
            </div>
          )}
        </div>
      )
    }
    return <div>{returnImages}</div>
  }

  return (
    <Styled.ProductPage
      inital={{opacity: 0}}
      animate={{opacity: currentProduct !== undefined ? 1 : 0}}
    >
      <Helmet title={titre + ''} htmlAttributes={{lang: 'fr'}} />
      {/* IFRAME BIGWAX */}
      <div className="firstSection">
        <div className="ProductContainer">
          <div className="left buySection">
            <GatsbyImage image={currentProduct?.images[0]} alt="produit" />
          </div>
          <div className="right buySection">
            <h1 className="title">{currentProduct?.displayedTitle}</h1>
            <div
              className="description"
              dangerouslySetInnerHTML={{__html: currentProduct?.description}}
            />
            <Styled.ProductComponent>
              <div id="product-component"></div>
            </Styled.ProductComponent>
          </div>
        </div>
        {currentProduct && renderImages(currentProduct.images)}
      </div>
      <div className="div100vh"></div>
      {/* <div className="goBack"> */}
      <div
        className="okBack"
        style={{
          pointerEvents: isScrollShop ? 'all' : 'none'
        }}
      >
        <Shop />
      </div>
      {/* </div> */}
    </Styled.ProductPage>
  )
}

export default ProductPage
